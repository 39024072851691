import axios from "axios";

const BASIC_URL=process.env.REACT_APP_N7_OFFCHAIN_API_URL;

const getCoList = () => {
    const url=BASIC_URL + "/cpSpNegotiations/coList";
    return axios.get(url);
}

const getCpList = () => {
    const url=BASIC_URL + "/cpSpNegotiations/cpList";
    return axios.get(url);
}

const getAllSpOffers = (spHash) => {
    const url=BASIC_URL + "/cpSpNegotiations/" + spHash + "/all";
    return axios.get(url);
}

const getSpOffer = (negotiationId) => {
    const url=BASIC_URL + "/cpSpNegotiations/id/" + negotiationId;
    return axios.get(url);
}

const acceptOffer = (offer) => {
    const url=BASIC_URL + "/cpSpNegotiations/accepted";
    return axios.put(url, offer);
}

const rejectOffer = (offer) => {
    const url=BASIC_URL+"/cpSpNegotiations/rejected";
    return axios.put(url, offer);
}

const negotiate = (offer) => {
    const url=BASIC_URL + "/cpSpNegotiations/negotiation";
    return axios.post(url, offer);
}

const cancelNegotiation = (negotiationId) => {
    const url=BASIC_URL + "/cpSpNegotiations/" + negotiationId + "/cancelled";
    return axios.delete(url);
}

const getSuccessionNegotiation = (negotiationId) => {;
    const url=BASIC_URL + "/cpSpNegotiations/coOffer/id/" + negotiationId;
    return axios.get(url);
}

const getContentStateId = (offerId) => {
    const url=BASIC_URL + "/cpSpNegotiations/contentStateId/" + offerId;
    return axios.get(url);
}

const updateContentState = (contentState) => {
    const url=BASIC_URL + "/cpSpNegotiations/contentStateSp";
    return axios.put(url, contentState);
}

export {getCoList, getCpList, getAllSpOffers, getSpOffer, acceptOffer, rejectOffer, negotiate, cancelNegotiation, getSuccessionNegotiation, getContentStateId, updateContentState};