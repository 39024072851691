import axios from "axios";

const BASIC_URL=process.env.REACT_APP_N7_OFFCHAIN_API_URL;

const getSpList = () => {
    const url=BASIC_URL + "/sps";
    return axios.get(url);
}

const getSpId = (spName) => {
    let sp = {
        spName: spName
    }
    const url=BASIC_URL;
    return axios.post(url, sp);
}
    
const getSpByHash = (spHashId) => {
    const url = BASIC_URL + "/" + spHashId;
    return axios.get(url);
}

const getUserInfo = (spUserName) => {
    const encodedSpUserName = encodeURIComponent(spUserName);
    const url=BASIC_URL + "/spUser/" + encodedSpUserName;
    return axios.get(url);
}
    
const addNewSp = (spUser) => {
    const url = BASIC_URL + "/spUser";
    return axios.post(url, spUser);
}

export {getSpList, getSpId, getSpByHash, getUserInfo, addNewSp};