import axios from "axios";

const BASIC_URL=process.env.REACT_APP_N7_OFFCHAIN_API_URL;

const getAllDiffusion = (spHash) => { 
    const url=BASIC_URL + "/diffusions/" + spHash + "/all";
    return axios.get(url);
}

const getAllUploadedDiffusion = (spHash) => {
    const url=BASIC_URL + "/diffusions/" + spHash + "/uploaded";
    return axios.get(url);
}

const getDiffusionById = (spHash, diffusionId) => {
    const url=BASIC_URL + "/diffusions/" + spHash + "/contentState/" + diffusionId;
    return axios.get(url);
}

export {getAllDiffusion, getAllUploadedDiffusion, getDiffusionById};